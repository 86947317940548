* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "open-sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "rooney-sans", sans-serif;
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: white;
}

p {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin-top: 0;
  padding-left: 0;
  font-size: 0.8em;
}

ul:last-child {
  margin-bottom: 0;
}

li {
  list-style: none;
  margin-left: 0;
}

dl {
  margin-top: 0;
}

dl:last-child {
  margin-bottom: 0;
}

dd {
  margin-left: 0;
  margin-bottom: 0.5em;
}

dd:last-child {
  margin-bottom: 0;
}

video {
  display: none;
}
